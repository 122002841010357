import { Country } from '@arland-bmnext/api-data'
import React from 'react'
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { SelectOption } from '../../util/select-option'
import { HelpTextProps } from '../HelpText'
import { AmountSelectorInput } from './elements/AmountSelector'
import { DateSelect } from './elements/DateSelect'
import { TextDescriptiveInput } from './elements/DescriptiveInput'
import { TextInput } from './elements/Input'
import { OddsFormatSelectElement } from './elements/OddsFormatSelect'
import { InputPhoneNumber } from './elements/PhoneNumber'
import { RadioGroupElement } from './elements/RadioGroup'
import { Select } from './elements/Select'
import SelflimitInput from './elements/SelflimitInput'
import TextView from './elements/TextView'
import { CustomMessageType, FormFieldType } from './FormBuilder'
import { CpfInput } from './elements/CpfInput'

export type FormElementProps = {
  label: string
  property: string
  placeholder: string
  required: boolean
  showOptionalIfNotRequired: boolean
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  getValues: UseFormGetValues<any>
  trigger: UseFormTrigger<any>
  validators: any
  formFieldType: FormFieldType
  type: string
  inputType: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  accept: string
  errors: FieldErrors
  selectOptions: SelectOption[]
  minValue: string | number
  maxValue: string | number
  autocomplete: string
  readonly: boolean
  disabled: boolean
  minLength: number
  maxLength: number
  pattern: RegExp
  value: any
  // valueConverter?: FormFieldValueConverter
  prefix: string
  postfix: string
  customMessage: string
  customMessageType: CustomMessageType
  customPatternErrorMessage?: string
  additionalActionText: string
  additionalActionFn: (...args) => any
  additionalActionOnlyIfValid?: boolean
  additionalActionOnlyIfNotEmpty?: boolean
  helpText?: HelpTextProps
  usedUp?: number
  currentLimit?: number
  nextPossibleIncreasement?: string
  additionalClassNames?: string
  showLabel?: boolean
  fixedCountryCallingCode?: any
  countries?: Country[]
  masked?: boolean
}

const FormElement = (props: FormElementProps) => {
  return (
    <>
      {props.formFieldType === FormFieldType.Input && <TextInput {...props} />}
      {props.formFieldType === FormFieldType.PhoneNumber && <InputPhoneNumber {...props} />}
      {props.formFieldType === FormFieldType.Select && <Select {...props} />}
      {props.formFieldType === FormFieldType.TextView && <TextView {...props} />}
      {props.formFieldType === FormFieldType.SelflimitInput && <SelflimitInput {...props} />}
      {props.formFieldType === FormFieldType.AmountSelector && <AmountSelectorInput {...props} />}
      {props.formFieldType === FormFieldType.DescriptiveInput && <TextDescriptiveInput {...props} />}
      {props.formFieldType === FormFieldType.RadioGroupElement && <RadioGroupElement {...props} />}
      {props.formFieldType === FormFieldType.OddsFormatSelect && <OddsFormatSelectElement {...props} />}
      {props.formFieldType === FormFieldType.DateSelect && <DateSelect {...props} />}
      {props.formFieldType === FormFieldType.CpfInput && <CpfInput {...props} />}
    </>
  )
}

export default FormElement
