import React, { useEffect, useState } from 'react'
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { CustomMessageType } from '../FormBuilder'
import { FormElementErrorMessage, FormElementLabel, TextInputProps } from './Input'
import { Country } from '@arland-bmnext/api-data'

type InputPhoneNumberProps = TextInputProps & {
  countries?: Country[]
}

type PhoneNumberPrefixSelectOption = {
  value: string
  name: string
  countryId: number
  countryName: string
}

export const InputPhoneNumber = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  placeholder = '',
  register = () => null,
  setValue = () => null,
  getValues = () => null,
  onChange = () => null,
  validators = {},
  minLength,
  maxLength,
  errors = undefined,
  disabled = false,
  value = '',
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  selectOnClick = false,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  fixedCountryCallingCode = null,
  countries = [],
  pattern = undefined,
}: InputPhoneNumberProps) => {
  const [countryCodes, setCountryCodes] = useState<PhoneNumberPrefixSelectOption[]>([])
  const [currentCountryCode, setCurrentCountryCode] = useState('')
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('')
  const [currentValue, setCurrentValue] = useState(value)

  const applyPhoneMask = (phone: string, countryCode: string): string => {
    const cleaned = phone.replace(/\D/g, '')
    if (countryCode === '55') {
      return cleaned
        .replace(/^(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .slice(0, 15)
    }
    return cleaned
  }

  const updateFullValue = (countryCode: string, phoneNumber: string) => {
    const sanitizedPhone = phoneNumber.replace(/\D/g, '')
    const newValue = `+${countryCode}${sanitizedPhone}`
    setCurrentValue(newValue)
    onChange(newValue)
    setValue(property, newValue, { shouldValidate: true, shouldTouch: true })
  }

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawPhone = event.target.value
    const maskedPhone = applyPhoneMask(rawPhone, currentCountryCode)
    setCurrentPhoneNumber(maskedPhone)
    updateFullValue(currentCountryCode, maskedPhone)
  }

  const handleCountryCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCode = event.target.value
    setCurrentCountryCode(newCode)
    setCurrentPhoneNumber('')
    updateFullValue(newCode, '')
  }

  useEffect(() => {
    if (countries.length && !countryCodes.length) {
      const formattedCountries = countries.map((country) => ({
        value: country.callingCode.toString(),
        name: `+${country.callingCode}`,
        countryId: country.id,
        countryName: country.name,
      }))
      const sortedCountries = fixedCountryCallingCode
        ? formattedCountries.filter((c) => c.value === fixedCountryCallingCode)
        : formattedCountries.sort((a, b) => a.countryName.localeCompare(b.countryName))

      setCountryCodes(sortedCountries)

      if (sortedCountries.length === 1) {
        setCurrentCountryCode(sortedCountries[0].value)
      }
    }
  }, [countries, countryCodes.length])

  useEffect(() => {
    if (value) {
      const parsed = parsePhoneNumber(value) as PhoneNumber | null
      if (parsed) {
        setCurrentCountryCode(parsed.countryCallingCode || '')
        setCurrentPhoneNumber(parsed.nationalNumber || '')
      }
    }
  }, [value])

  return (
    <div className="phone-number-input flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        readonly={readonly}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div className="phone-number-input-inner relative flex space-x-2">
        <div className="phone-number-input-predial flex w-[150px] relative">
          <select
            className={`phone-number-input-predial-select flex w-full bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md h-[42px] pl-2 pr-8 py-[10px] focus:outline-none focus:border-primary appearance-none cursor-pointer transition hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder`}
            onChange={handleCountryCodeChange}
            value={currentCountryCode}
            disabled={!!fixedCountryCallingCode}
          >
            {countryCodes.map((option) => (
              <option key={option.countryId} value={option.value}>
                {option.name} ({option.countryName})
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="absolute right-3 bottom-[13px] opacity-60 pointer-events-none"
          />
        </div>

        <input
          className={`phone-number-input-input flex w-[calc(100%-150px)] break-all bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md h-[42px] px-2 py-[10px] ${
            errors ? 'border-red-500' : ''
          }`}
          disabled={disabled}
          inputMode="numeric"
          onChange={handlePhoneNumberChange}
          onClick={(e) => selectOnClick && e.currentTarget.select()}
          placeholder={placeholder}
          readOnly={readonly}
          type="tel"
          value={currentPhoneNumber}
        />

        <input
          id={property}
          name={property}
          type="hidden"
          value={currentValue}
          {...register(property, { required, validate: validators })}
        />
      </div>

      {additionalActionText && (
        <div
          className="phone-number-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  )
}
